import(/* webpackMode: "eager" */ "/tmp/build_30672272/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/app/assets/applestore.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/app/assets/embarca-ai-icon.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/app/assets/googleplay.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_30672272/src/components/banner/LGPDCookie.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/americanexpress.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/elo.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/hiper.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/hipercard.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/mastercard.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/paypal.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/pix.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_30672272/src/components/footer/PaymentMethods/visa.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_30672272/src/components/footer/Sitemap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_30672272/src/context/toastProvider.tsx");
